import * as React from "react";

class Subtitle extends React.Component {
  render() {
    const { subtitle } = this.props;
    const CustomTag = subtitle.heading ? `${subtitle.heading}` : "h3";

    return (
      <CustomTag
        className={
          "pb-4 pb-sm-2 lineHeight1_7 " +
          subtitle.colorClass +
          " " +
          (subtitle.classSeparator ? subtitle.classSeparator : "") +
          " " +
          (subtitle.extraClass ? subtitle.extraClass : "") +
          " " +
          (subtitle.heightClass ? subtitle.heightClass : "")
        }
      >
        {subtitle.text}
      </CustomTag>
    );
  }
}

export default Subtitle;
