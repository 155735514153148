import * as React from "react";

//components
import Paragraph from "./_partials/_paragraph";
import Title from "./_partials/_title";
import Subtitle from "./_partials/_subtitle";

class TitleSeparator extends React.Component {
  render() {
    const { info , centeredPage} = this.props;

    return (
      <>

        {info.subtitleFirst && <Subtitle subtitle={info.subtitle}></Subtitle>} 
        {info.title && (
          <Title title={info.title} centeredPage={centeredPage}></Title>
        )}
        {info.subtitleFirst === false && <Subtitle subtitle={info.subtitle}></Subtitle>}
        {info.separator && <hr className={"hrStyle mb-30 mt-10 " + (info.classSeparator ? info.classSeparator : "") } />}
        {info.text && <Paragraph text={info.text} />}
      </>
    );
  }
}

export default TitleSeparator;
