import * as React from "react";
import { withTrans } from "../../i18n/withTrans";

// components
import TitleSeparator from "../titleSeparator";

class Redirect extends React.Component {
  render() {
    const { text, loading } = this.props;

    return (
      <div className="pt-menu">
        <div className="paddingSidesBig pt-150 pb-150 text-center">
          <TitleSeparator info={text}></TitleSeparator>
          <img src={loading.src} alt={loading.alt}></img>
        </div>
      </div>
    );
  }
}

export default withTrans(Redirect);
