import * as React from 'react';
import Parser from 'html-react-parser';

class Paragraph extends React.Component {
  render() {
    const { text } = this.props;
    const CustomTag = text.heading ? `${text.heading}` : 'p';

    return (
      <div className='textContent pb-10'>
        <CustomTag
          className={
            text.colorClass +
            (text.heightClass ? ' ' + text.heightClass : '') +
            ' ' +
            (text.extraClass ? text.extraClass : 'font17 lineHeight2') +
            ' m-0'
          }
        >
          {Parser(text.text)}
        </CustomTag>
      </div>
    );
  }
}

export default Paragraph;
