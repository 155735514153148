import * as React from "react";
import Parser from "html-react-parser";

class Title extends React.Component {
  render() {
    const { title } = this.props;

    const CustomTag = title.heading ? `${title.heading}` : "h2";

    return (
      <CustomTag className={"pb-1 letterSpacing3 fontWeightBold lineHeight1_7 " + title.colorClass + " " + (title.extraClass ? title.extraClass : "")}>
        {Parser(title.text)}
      </CustomTag>
    );
  }
}

export default Title;
