// icons
import LoadingIcon from "../../images/icon/loadingIcon.svg";

export const pageTexts = (t) => {
  return {
    redirect: {
      title: {
        text: t("redirect.redirecting"),
        colorClass: "colorDark",
        extraClass: "font34 m-0",
        heading: "h3",
      },
      text: {
        text: t("redirect.wait"),
        colorClass: "colorDark",
        extraClass: "font26",
        heading: "h4",
      },
      separator: true,
    },
    loading: {
        src: LoadingIcon,
        alt: t("redirect.loadingAlt")
    }
  };
};
